import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PageSlides, SlideParallaxType } from '@re_point/react-page-slides';

import SlideContext from '../../context/slides';

import HeroBanner from './HeroBanner';
import About from './About';
import Services from './Services';
import ContactUs from './ContactUs';

import BackgroundImage1 from '../../assets/background-01@2x.jpg';
import BackgroundImage2 from '../../assets/background-02@2x.jpg';
import BackgroundImage3 from '../../assets/background-03@2x.jpg';
import BackgroundImage4 from '../../assets/background-04@2x.jpg';

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { currentSlide, setCurrentSlide } = useContext(SlideContext);

  const slides = [
    {
      content: <HeroBanner />,
      style: {
        backgroundImage: `url(${BackgroundImage1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    },
    {
      content: <About />,
      style: {
        backgroundImage: `url(${BackgroundImage2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
      },
    },
    {
      content: <Services />,
      style: {
        backgroundImage: `url(${BackgroundImage3})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
      },
    },
    {
      content: <ContactUs />,
      style: {
        backgroundImage: `url(${BackgroundImage4})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    },
  ];

  return (
    <Root>
      {isMobile ? (
        <Box>
          <HeroBanner />
          <About />
          <Services />
          <ContactUs />
        </Box>
      ) : (
        <PageSlides
          enableAutoScroll={true}
          transitionSpeed={1000}
          slides={slides}
          parallax={{
            offset: 0.6,
            type: SlideParallaxType.cover,
          }}
          currentSlideIndex={currentSlide}
          onChange={(index) => setCurrentSlide(index)}
        />
      )}
    </Root>
  );
}

const Root = styled(Box)({});
