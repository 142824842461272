import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import SlideContext from '../context/slides';

import LogoImage from '../assets/logo_anova_light.png';

const menuItems = [
  {
    text: 'Home',
    link: '/',
  },
  {
    text: 'About',
    link: '#about',
  },
  {
    text: 'Services',
    link: '#services',
  },
  {
    text: 'Contact Us',
    link: '#contact-us',
  },
];

export default function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { currentSlide, setCurrentSlide } = useContext(SlideContext);
  const onMenuItemClick = (index) => setCurrentSlide(index);

  return (
    <Root component="header">
      <Container>
        <Stack direction="row" justifyContent="space-between" py={2}>
          <Logo src={LogoImage} alt="Anova Logo" />
          {!isMobile && (
            <nav>
              <Stack
                component="ul"
                direction="row"
                justifyContent="flex-end"
                spacing={2}
              >
                {menuItems.map((m, i) => (
                  <Link
                    key={m.text}
                    onClick={() => onMenuItemClick(i)}
                    className={currentSlide === i && 'active'}
                  >
                    {m.text}
                  </Link>
                ))}
              </Stack>
            </nav>
          )}
        </Stack>
      </Container>
    </Root>
  );
}

const Root = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  zIndex: 100,
});

const Logo = styled('img')({
  height: 42,
});

const Link = styled('li')({
  position: 'relative',
  padding: '8px 6px',
  color: '#fff',
  cursor: 'pointer',
  textDecoration: 'none',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    height: 3,
    width: '0%',
    backgroundColor: '#fff',
    transition: '300ms',
  },
  '&:hover::after, &.active::after': {
    width: '100%',
  },
});
