import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ParallaxProvider } from 'react-scroll-parallax';

import SlideContext from './context/slides';
import Navbar from './components/Navbar';
import Home from './pages/Home';

const Roboto = "'Roboto', sans-serif";
const Ubuntu = "'Ubuntu', serif";

const theme = createTheme({
  typography: {
    fontFamily: Roboto,
    h1: {
      fontFamily: Ubuntu,
      fontWeight: 700,
    },
    h2: {
      fontFamily: Ubuntu,
      fontWeight: 700,
    },
    h3: {
      fontFamily: Roboto,
    },
    h4: {
      fontFamily: Roboto,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: '#202020',
        },
        svg: {
          maxWidth: '100%',
          maxHeight: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
          listStyle: 'none',
        },
      },
    },
  },
});

function App() {
  const [currentSlide, setCurrentSlide] = useState(0);
  // console.log('currentSlide:', currentSlide);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ParallaxProvider>
          <SlideContext.Provider value={{ currentSlide, setCurrentSlide }}>
            <CssBaseline />
            <Router>
              <div className="App">
                <Navbar />
                <Routes>
                  <Route path="/" element={<Home />} />
                </Routes>
              </div>
            </Router>
          </SlideContext.Provider>
        </ParallaxProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
