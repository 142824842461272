import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';

import BackgroundImage from '../../assets/background-04@2x.jpg';

export default function ContactUs() {
  return (
    <Root id="contact-us">
      <Container>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Typography variant="h2" mb={2}>
              Contact Us
            </Typography>
            <Typography>
              If you are ready to grow your business or looking for any support
              from any of our services, our team are available to help and
              respond back to you within 24 hours of receipt of your email. We
              look forward to hearing from you.
            </Typography>
            <Typography variant="h5" mt={4}>
              Registered office
            </Typography>
            <Typography>
              ANOVA Consultancies FZCO
              <br />
              Dubai Silicon Oasis,
              <br />
              DDP, Building A2, Dubai
              <br />
              United Arab Emirates
            </Typography>
            <a href="mailto:contact@anovaconsultancies.org">
              contact@anovaconsultancies.org
            </a>
          </Grid>
          <Grid item md={6} xs={12}></Grid>
        </Grid>
      </Container>
    </Root>
  );
}

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  inset: 0,
  color: '#fff',
  // textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    padding: `${theme.spacing(8)} 0`,
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -2,
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(to bottom left, #FC6B74, #917545)',
    opacity: 0.65,
    zIndex: -1,
  },
  a: {
    color: '#fff',
    fontSize: '1.125rem',
  },
  p: {
    margin: `${theme.spacing(1.5)} 0`,
    fontSize: '1.125rem',
  },
}));
