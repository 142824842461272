import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

import BackgroundImage from '../../assets/background-02@2x.jpg';

export default function About() {
  return (
    <Root id="about">
      <Container>
        <Box maxWidth={{ md: '50%' }}>
          <Typography variant="h2" mb={2}>
            About
          </Typography>
          <Typography>
            ANOVA consultancies is a Dubai based consultancy services firm. We
            focus on the people side of strategy working with leaders at all
            levels to help them make better decisions, convert those decisions
            to actions and deliver results.
          </Typography>
          <Typography>
            As an emerging consulting firm, we strive to build strong, trusting
            relationships and we take immense pride on our work and our people.
          </Typography>
          <Typography>
            We are driven to find opportunities and solutions for your
            businesses. Our services are designed to grow with our clients and
            we are here to expertly guide you through.
          </Typography>
          <Typography>
            We strive to provide all our clients with the best of quality in all
            our interactions and services.
          </Typography>
        </Box>
      </Container>
    </Root>
  );
}

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  inset: 0,
  color: '#fff',
  // textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    padding: `${theme.spacing(8)} 0`,
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -2,
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(to bottom left, #4ED2BB, #17A4C0)',
    opacity: 0.65,
    zIndex: -1,
  },
  p: {
    margin: `${theme.spacing(1.5)} 0`,
    fontSize: '1.125rem',
  },
}));
