import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

import BackgroundImage from '../../assets/background-01@2x.jpg';

export default function HeroBanner() {
  return (
    <Root id="hero">
      <Container>
        <Typography
          variant="h1"
          sx={{ fontSize: { md: 60, xs: 40 }, fontWeight: 700 }}
        >
          Anova Consultancies
        </Typography>
        <Typography
          sx={{
            mt: 1,
            mx: 'auto',
            maxWidth: 900,
            fontSize: { md: 24, xs: 20 },
          }}
        >
          A passionate & caring team, generating creative solutions, delivering
          optimum quality results, and building a community of happy customers
          globally.
        </Typography>
      </Container>
    </Root>
  );
}

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 100,
  paddingBottom: 40,
  position: 'absolute',
  inset: 0,
  color: '#fff',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    minHeight: '70vh',
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -2,
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(to bottom right, #236AAF, #9232B9)',
    opacity: 0.5,
    zIndex: -1,
  },
}));
