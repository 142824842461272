import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';

import BackgroundImage from '../../assets/background-03@2x.jpg';

export default function About() {
  return (
    <Root id="services">
      <Container>
        <Typography variant="h2" mb={4}>
          Services
        </Typography>
        <Grid container spacing={8}>
          <Grid item md={4} xs={12}>
            <Typography variant="h3">Management Consulting</Typography>
            <Typography>
              We make sure that the right processes are in place to enable a
              company to work effectively, legally and in an organised manner.
              When growth is taking place, we can assist the organisation in
              developing the capacity needed to deliver it effectively.
            </Typography>
            <Typography>
              We have a community of extraordinary people who hold particular
              expertise at an operational level, as well as of technical
              knowledge. The services and support we offer to our clients are
              tailored to the needs of each business.
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h3">IT Consulting</Typography>
            <Typography>
              We help clients assess different technology strategies and, in
              doing so, align their technology strategies with their business or
              process strategies.
            </Typography>
            <Typography>
              Our IT experts focus on providing strategic IT advice on how to
              modify or improve solutions to reach the desired goal. Our
              technical experts work closely with clients to help them solve IT
              problems. The support includes everything from basic network
              analytics assistance all the way up to managed IT solutions
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h3">HR Consulting</Typography>
            <Typography>
              We assist organizations in implementing activities related to the
              management of an organisation's human capital and the HR function.
              Our key propositions include strategic workforce planning which
              encompasses all activities required to recruit
            </Typography>
            <Typography>
              Our highly skilled team helps organizations to build the best
              teams and enable them to ensure that the organisation's core
              values are intact, despite expansion and change. We are technology
              driven to maximize efficiency and quality.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
}

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  inset: 0,
  color: '#fff',
  // textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    padding: `${theme.spacing(8)} 0`,
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -2,
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(to bottom left, #57444C, #009CEA)',
    opacity: 0.85,
    zIndex: -1,
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  p: {
    margin: `${theme.spacing(1.5)} 0`,
    // fontSize: '1.125rem',
  },
}));
